<script setup>
import {inject, defineModel} from 'vue'

const resultObj = inject('resultObj');
const tabSelect = inject('tabSelect');
const tabSelectTitle = inject('tabSelectTitle');
const styles = inject('styles');
const dropTabId = defineModel('dropTabId');

const goto = (url) => {
    top.location.href=url;
}

const gotoShow = (boxID, locationtotalgroup_id) => {
    let url = '/show/' + resultObj.value.action + '/' + boxID + '/' + locationtotalgroup_id + '/'
    top.location.href=url;
}

const gotoLocation = (boxID) => {
    let url = '/overview/' + boxID
    top.location.href=url;
}

let isActive = (id) => {

    let ret = false;
    if (Number(resultObj.value.tab_css_id) == Number(id)){
        ret = true;
    }

    return ret;
}

</script>
<template>
<div class="tab">
<button v-if="Number(tabSelect.length) > 0" class="tablinks dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"> 
    {{ tabSelectTitle }}
</button>

<ul class="dropdown-menu">
    <template v-for="(item, index) in tabSelect" :key="index">
        <template v-if="item.id == dropTabId">
            <li><a class="dropdown-item active" @click="gotoLocation(item.boxID);" style="cursor: pointer;">
                <span :class="styles.iconAngelRight"></span>{{ item.title }}</a>
            </li>
            <div class="dropdown-divider"></div>
            <template v-for="(row, index2) in resultObj.tab_navigation" :key="index2">
                <template v-for="(mitem, index3) in row" :key="index3">
                    <template v-if="mitem.locationgroup_id == dropTabId">
                        <template v-if="mitem.locationtotalgroup_id">
                            <li v-if="resultObj.tab_main_is_unlocked"><a class="dropdown-item" :class="{ active: isActive(mitem.id) }" @click="gotoShow(mitem.boxID, mitem.locationtotalgroup_id)" style="cursor: pointer;">
                                <span :class="styles.iconAngelRight"></span>{{ mitem.title }}</a>
                            </li>
                            <li v-else><a class="dropdown-item disabled" href="#"><span :class="styles.iconAngelRight"></span> {{ mitem.title }}</a></li>
                        </template>
                        <template v-else-if="mitem.id">
                            <li v-if="mitem.visible == 'true'">
                                <a class="dropdown-item" :class="{ active: isActive(mitem.id) }" @click="goto(mitem.url)" style="cursor: pointer;">
                                    <span :class="styles.iconAngelRight"></span> {{ mitem.title }}</a>
                                </li>
                            <li v-else><a class="dropdown-item disabled" href="#"><span :class="styles.iconAngelRight"></span> {{ mitem.title }}</a></li>
                        </template>
                    </template>
                </template>
            </template>
            <div class="dropdown-divider"></div>
        </template>
        <template v-else>

            <li><a class="dropdown-item" @click="gotoLocation(item.boxID);" style="cursor: pointer;">
                <span :class="styles.iconAngelRight"></span>{{ item.title }}</a>
            </li>
        </template>
    </template>
</ul>
</div>
</template>