<script setup>
import {inject, defineModel} from 'vue'

const resultObj = inject('resultObj');
const tabSelect = inject('tabSelect');
const tabSelectTitle = inject('tabSelectTitle');
const styles = inject('styles');
const dropTabId = defineModel('dropTabId');

const goto = (url) => {
    top.location.href=url;
}

const gotoShow = (boxID, locationtotalgroup_id) => {
    let url = '/show/' + resultObj.value.action + '/' + boxID + '/' + locationtotalgroup_id + '/'
    top.location.href=url;
}

let isActive = (id) => {

    let ret = false;
    if (Number(resultObj.value.tab_css_id) == Number(id)){
        ret = true;
    }

    return ret;
}

const setNewGroup = (id) => {
    //console.log(id);
    dropTabId.value = id;

}
</script>
<template>
<div class="tab">
  

<button v-if="Number(tabSelect.length) > 1" class="tablinks dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"> 
    {{ tabSelectTitle }}
</button>

<ul class="dropdown-menu">
    <template v-for="(item, index) in tabSelect" :key="index">
    <li v-if="item.id == dropTabId"><a class="dropdown-item active" @click="setNewGroup(item.id)" style="cursor: pointer;">
        <span :class="styles.iconAngelRight"></span>{{ item.title }}</a>
    </li>
    <li v-else><a class="dropdown-item" @click="setNewGroup(item.id)" style="cursor: pointer;"><span :class="styles.iconAngelRight"></span>
        {{ item.title }}</a>
    </li>
    </template>
</ul>


<template v-for="(row, index1) in resultObj.tab_navigation" :key="index1">
    <template v-for="(item, index) in row" :key="index">
        <template v-if="item.locationgroup_id == dropTabId">
            <template v-if="item.locationtotalgroup_id">
                <button v-if="resultObj.tab_main_is_unlocked" 
                :id="'tab_' + item.id" class="tablinks" 
                :class="{ active: isActive(item.id) }" 
                @click="gotoShow(item.boxID, item.locationtotalgroup_id)" v-html="item.title"></button>
                <button v-else disabled="true" v-html="item.title"></button>
            </template>
            <template v-else-if="item.id">
                <button v-if="item.visible == 'true'" 
                :id="'tab_' + item.id" class="tablinks" 
                :class="{ active: isActive(item.id) }" 
                @click="goto(item.url)" v-html="item.title"></button>
                <button v-else disabled="true" v-html="item.title"></button>
            </template>
        </template>
    </template>
</template>
</div>
</template>

<style scoped>
button {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 13px !important;
}
</style>